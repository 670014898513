<template>
  <ul
      :style="theme.topbar.color"
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'

export default {
  data() {
    return {
      theme: {
        topbar: {
          color: ""
        },
      }
    }
  },
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  watch: {
    '$store.state.appConfig.theme': function() {
      if (this.$store.state.appConfig.layout.skin == 'dark') {
        this.theme.topbar.color = ""
      } else {
        this.theme.topbar.color = this.$store.state.appConfig.theme.topbar.textcolor

      }
    },
  },

  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
}
</script>
